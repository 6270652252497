import React from "react";
import Slider from "react-slick";
import "./DashboardSlider.css";

const DashboardSlider = () => {

const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
autoplaySpeed: 3000,
};

return (

<div>

{/*  */}

<div className="flex_col_home_Dash">

<Slider {...settings} className="slider_div_Dash">
<div>
<img src="https://colorlib.com/wp/wp-content/uploads/sites/2/free-dashboard-templates-1.jpg"></img>
</div>

{/*  */}

<div>
<img src="https://colorlib.com/wp/wp-content/uploads/sites/2/concept-free-admin-dashboard-panel.jpg"></img>
</div>

{/*  */}

<div>
<img src="https://images.ctfassets.net/w8fc6tgspyjz/28CsLatWQmyqPbv2z070WR/bca223c20a9f07c9d095bcc0776e64ed/time_tracking_dashboards.png"></img>
</div>
<div>
<img src="https://colorlib.com/wp/wp-content/uploads/sites/2/adminty-free-admin-dashboard-template.jpg"></img>
</div>

{/*  */}

</Slider>

</div>
</div>

);

};

export default DashboardSlider;
