import React from "react";
import Slider from "react-slick";
import Web_devImg from "../Images/web_development.jpg";
import "./slider.css";

const SliderComponent = () => {
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
autoplaySpeed: 3000,
};

return (

<div>

{/*  */}

<div className="flex_col_home">
<Slider {...settings} className="slider_div">
<div>
<p>Web Software Development Solutions</p>
<h1>
Empowering Your Business Growth with Cutting-Edge Web Software
</h1>
</div>

{/*  */}

<div>
<h1>Driving Digital Transformation for Business Growth</h1>
</div>

{/*  */}

<div>
<h1>Comprehensive Solutions Tailored for Your Business Success</h1>
</div>

{/*  */}

</Slider>

<img className="slider_img" src={Web_devImg}></img>
</div>
</div>
);
};

export default SliderComponent;
