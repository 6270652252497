import React from "react";
import Slider from "react-slick";
import "./technologiesSlider.css";

const TechnologiesSlider = () => {
const settings = {
dots: true,
infinite: true,
speed: 100,
slidesToShow: 7,
slidesToScroll: 1,
autoplay: true,
autoplaySpeed: 3000,
};

return (
<div>
{/*  */}

<div className="Techflex_col_home">
<h1>
Transform Your Business with <br></br> Our Tech Solutions
</h1>

<Slider {...settings} className="Techslider_div">
<img src="https://nexgencode.com/images/react-js.webp"></img>

{/*  */}

<img src="https://nexgencode.com/images/mySql.webp"></img>

{/*  */}

<img src="https://nexgencode.com/images/php.webp"></img>

<img src="http://localhost:3000/static/media/node%20js.47a74ad1b11be38979b6.png"></img>

<img src="https://nexgencode.com/images/jquery.webp"></img>

<img src="https://nexgencode.com/images/WordPress.webp"></img>

<img src="http://localhost:3000/static/media/JavaScript.7e4ca52d27e4cdc0424a.png"></img>

{/*  */}
</Slider>
</div>
</div>
);
};

export default TechnologiesSlider;
